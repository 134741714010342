/* eslint-disable prettier/prettier */
import {
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  TOGGLE_APP_DRAWER,
  SET_CHAT_USER_ID,
} from '@crema/types/actions/Common.action';
import { createReducer } from '@reduxjs/toolkit';
import { CommonType } from '@crema/types/models/Common';
import { FetchErrorAction, ShowMsgAction, ShowErrorMessage, SetUpdating } from './ActionTypes/Common';
import { SHOW_DELETE_MODAL, SHOW_MODAL } from '@crema/constants/ActionTypes';

const INIT_STATE: CommonType = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  message: '',
  showDeleteModal: false,
  showModal: false,
  chatUserID: null,
  updating: false,
};

const commonReducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase(FETCH_START, (state) => {
      state.error = '';
      state.message = '';
      state.loading = true;
    })
    // .addCase(UPDATING_CONTENT, (state, action) => {
    //   state.error = '';
    //   state.message = '';
    //   state.updatingContent = true;
    // })
    .addCase(FETCH_SUCCESS, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    })
    .addCase(ShowMsgAction, (state, action) => {
      state.error = '';
      state.message = action.payload;
      state.loading = false;
    })
    .addCase(ShowErrorMessage, (state: any, action: any) => {
      state.error = action.payload;
      state.message = '';
      state.loading = false;
    })
    .addCase(FetchErrorAction, (state, action) => {
      state.error = action.payload;
      state.message = '';
      state.loading = false;
    })
    .addCase(HIDE_MESSAGE, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    })
    .addCase(TOGGLE_APP_DRAWER, (state) => {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    })
    .addCase(SHOW_DELETE_MODAL, (state: any, action: any) => {
      state.showDeleteModal = action.payload;
    })
    .addCase(SHOW_MODAL, (state: any, action: any) => {
      state.showModal = action.payload;
    })
    .addCase(SET_CHAT_USER_ID, (state: any, action: any) => {
      state.chatUserID = action.payload;
    })
    .addCase(SetUpdating, (state: any, action: any) => {
      state.updating = action.payload;
    });
});

export default commonReducer;
