import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const Courses = React.lazy(() => import('../../../modules/dashboards/Courses'));
const PendingCourses = React.lazy(
  () => import('../../../modules/dashboards/PendingCourses'),
);
const ViewCourse = React.lazy(
  () => import('../../../modules/dashboards/CourseDetails/'),
);

const Users = React.lazy(() => import('../../../modules/dashboards/Users'));
const UserDetails = React.lazy(
  () => import('../../../modules/dashboards/UserDetails'),
);

const Instructors = React.lazy(
  () => import('../../../modules/dashboards/Teachers'),
);

const CreateCourse = React.lazy(
  () => import('../../../modules/dashboards/CreateCourse'),
);

const Category = React.lazy(
  () => import('../../../modules/dashboards/Category'),
);

const TrendingCourses = React.lazy(
  () => import('../../../modules/dashboards/AppManagement/TrendingCourses'),
);

const PopularCourses = React.lazy(
  () => import('../../../modules/dashboards/AppManagement/PopularCourses'),
);

const PromoCourses = React.lazy(
  () => import('../../../modules/dashboards/AppManagement/PromoCourses'),
);

const ParticipientViewed = React.lazy(
  () => import('../../../modules/dashboards/AppManagement/ParticipientViewed'),
);

const NewCourses = React.lazy(
  () => import('../../../modules/dashboards/AppManagement/NewCourses'),
);

const Chat = React.lazy(() => import('../../../modules/dashboards/Chat'));

const CreateTeacher = React.lazy(
  () => import('../../../modules/dashboards/Teachers/CreateTeacher'),
);

const SupportAgents = React.lazy(
  () => import('../../../modules/dashboards/SupportAgents'),
);

const CreateSupportAgent = React.lazy(
  () => import('../../../modules/dashboards/SupportAgents/CreateSupportAgent'),
);

const CourseAssignments = React.lazy(
  () => import('../../../modules/dashboards/CourseDetails/Assignment'),
);

const CourseQuizes = React.lazy(
  () => import('../../../modules/dashboards/CourseDetails/Quiz'),
);

const ViewAssignmentDetails = React.lazy(
  () =>
    import(
      '../../../modules/dashboards/CourseDetails/Assignment/AssignmentDetails'
    ),
);
const AssignmentSubmission = React.lazy(
  () =>
    import(
      '../../../modules/dashboards/CourseDetails/Assignment/ViewSubmissions'
    ),
);

const QuizDetails = React.lazy(
  () => import('../../../modules/dashboards/CourseDetails/Quiz/QuizDetails'),
);

const QuizSubmission = React.lazy(
  () => import('../../../modules/dashboards/CourseDetails/Quiz/QuizSubmission'),
);

const BonusContent = React.lazy(
  () => import('../../../modules/dashboards/CourseDetails/Bonus'),
);

const WithdrawalNotification = React.lazy(
  () => import('../../../modules/dashboards/WithdrawalNotifications'),
);
const TaxModule = React.lazy(
  () => import('../../../modules/dashboards/AppManagement/TaxModule'),
);

const Finance = React.lazy(() => import('../../../modules/dashboards/Finance'));

const MobileViewArrangement = React.lazy(
  () => import('../../../modules/dashboards/MobileViewArrangement'),
);

const Transactions = React.lazy(
  () => import('../../../modules/dashboards/Transactions'),
);

const RecentActivites = React.lazy(
  () => import('../../../modules/dashboards/RecentActivities'),
);

const SuspenseFallback = () => <div>Loading...</div>;

export const dashBoardConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/courses',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <Courses />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/users',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <Users />,
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/users/userdetails/:id'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <UserDetails />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/course/coursedetails/:id'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <ViewCourse />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/instructors'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <Instructors />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/transactions'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <Transactions />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/recentactivities'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <RecentActivites />
      </Suspense>
    ),
  },
  // {
  //   permittedRole: [RoutePermittedRole.Admin],
  //   path: ['/tickets'],
  //   element: <Navigate to='/tickets/all' />,
  // },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/create',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <CreateCourse />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/categories',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <Category />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/trending',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <TrendingCourses />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/popular',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <PopularCourses />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/promo',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <PromoCourses />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/participientViewed',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <ParticipientViewed />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/new',
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <NewCourses />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.SupportAgent],
    path: ['/chat'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <Chat />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.SupportAgent, RoutePermittedRole.Admin],
    path: ['/chat/:id'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <Chat />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/createTeacher'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <CreateTeacher />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/supportagents'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <SupportAgents />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/createSupportAgent'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <CreateSupportAgent />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/assignments'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <CourseAssignments />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/course/:id/assignment/:assignmentID'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <ViewAssignmentDetails />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/course/:id/viewsubmission/:submissionID'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <AssignmentSubmission />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/quizes'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <CourseQuizes />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/course/:id/quiz/:id'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <QuizDetails />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/course/:id/submission/:id'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <QuizSubmission />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/bonus'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <BonusContent />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/pending'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <PendingCourses />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/withdrawalNotifications'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <WithdrawalNotification />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/tax'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <TaxModule />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/finance'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <Finance />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/coursesarrangement'],
    element: (
      <Suspense fallback={<SuspenseFallback />}>
        <MobileViewArrangement />
      </Suspense>
    ),
  },
];
