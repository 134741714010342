export const authRole = {
  Admin: ['Admin'],
  User: ['User', 'Admin'],
  SupportAgent: ['Agent'],
  Teacher: ['Teacher'],
};

export const defaultUser: any = {
  uid: '12345678',
  displayName: 'Admin',
  email: 'admin@gmail.com',
  token: 'access-token',
  role: 'Admin',
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const allowMultiLanguage = process.env.REACT_APP_MULTILINGUAL === 'true';
export const fileStackKey = process.env.REACT_APP_FILESTACK_KEY as string;
export const initialUrl = process.env.REACT_APP_INITIAL_URL as string; // this url will open after login
