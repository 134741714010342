import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import AppAnimate from '@crema/components/AppAnimate';
import AppGridContainer from '@crema/components/AppGridContainer';
import {Grid} from '@mui/material';
import {MyProfile} from '@crema/modules/dashboards/Academy';
import {useAppSelector, useAppDispatch} from '../../toolkit/hooks';
import {GetAdminAnalyticsData} from '../../toolkit/actions';
import {useAuthUser} from '@crema/hooks/AuthHooks';
import AppInfoView from '@crema/components/AppInfoView';
const MyCourses = React.lazy(() => import('modules/dashboards/Courses'));
const GeneralStats = React.lazy(() => import('./GeneralStates'));

const Analytics = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetAdminAnalyticsData());
  }, []);

  const {adminanalytics} = useAppSelector(({dashboard}) => dashboard);
  const {user} = useAuthUser();

  return (
    <>
      <AppAnimate animation='transition.slideUpIn' delay={80}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          {user?.type == 'Admin' || user?.type == 'Teacher' ? (
            <GeneralStats adminanalytics={adminanalytics} />
          ) : null}
          <AppGridContainer spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3.5}
              xl={3}
              xxl={3}
              component={null}
            >
              <MyProfile user={user} />
            </Grid>
            {user?.type == 'Admin' || user?.type == 'Teacher' ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8.5}
                xl={9}
                xxl={9}
                component={null}
              >
                <MyCourses />
              </Grid>
            ) : null}
          </AppGridContainer>
        </Box>
      </AppAnimate>
      <AppInfoView />
    </>
  );
};

export default Analytics;
