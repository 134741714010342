import {
  GET_CATEGORY_DATA,
  GET_TEACHERS_LISTING,
  GET_COURSES_LISTING,
  GET_ADMIN_ANALYTICS,
  GET_ALL_COURSES,
  GET_ALL_USERS,
  GET_ALL_CATEGORIES,
  SET_ALL_CHAT_USERS,
  GET_NEW_COURSES,
  SET_SUPPORT_AGENTS,
  GET_PENDING_COURSES,
  GET_WITHDRAWAL_NOTIFICATIONS,
  SET_FINANCE_LIST,
} from '@crema/types/actions/Dashboard.action';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {Dispatch} from 'redux';
import {AppActions} from '@crema/types/actions';
import jwtAxios from '@crema/services/auth/jwt-auth';

export const getCategoryList = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get('/category')
      .then((data: any) => {
        dispatch(fetchSuccess());
        dispatch({type: GET_CATEGORY_DATA, payload: data.data.responseData});
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getTeachersListing = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`user/allTeachers`)
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_TEACHERS_LISTING, payload: data?.data?.data});
        } else {
          dispatch(fetchError('Something went wrong, Please try again!'));
        }
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getCoursesListing = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    await jwtAxios
      .get(`course/listing?isFilter=true&limit=5000`)
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_COURSES_LISTING,
            payload: data.data.responseData,
          });
        } else {
          dispatch(fetchError('Something went wrong, Please try again!'));
        }
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getPendingCoursesList = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    await jwtAxios
      .get(`course/inActive`)
      .then((data: any) => {
        dispatch(fetchSuccess());
        dispatch({type: GET_PENDING_COURSES, payload: data.data.data});
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const GetAdminAnalyticsData = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get('analytics/adminAnalytics')
      .then((data: any) => {
        dispatch(fetchSuccess());
        dispatch({type: GET_ADMIN_ANALYTICS, payload: data.data.data});
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getAllCourseListing = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get('course/listing')
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_ALL_COURSES, payload: data.data.responseData});
        } else {
          dispatch(fetchError('Something went wrong, Please try again!'));
        }
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getNewCoursesListing = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get('course/listing')
      .then((data: any) => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_NEW_COURSES,
          payload: data?.data?.responseData[0]?.newCourses,
        });
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getAllUsersListing = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get('user/allUsers')
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_ALL_USERS, payload: data.data.data});
        } else {
          dispatch(fetchError('Something went wrong, Please try again!'));
        }
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getALLCategoriesListing = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get('category')
      .then((res: any) => {
        dispatch(fetchSuccess());
        dispatch({type: GET_ALL_CATEGORIES, payload: res?.data?.responseData});
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getChatListing = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get('chat/allUsers')
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: SET_ALL_CHAT_USERS,
            payload: res?.data?.responseData,
          });
        } else {
          dispatch(fetchError('Something went wrong, Please try again!'));
        }
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getSupportAgentListing = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    await jwtAxios
      .get(`user/allAgents`)
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: SET_SUPPORT_AGENTS, payload: data.data.data});
        } else {
          dispatch(fetchError('Something went wrong, Please try again!'));
        }
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getWithdrawalNotifications = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    await jwtAxios
      .get(`notifications/getWithdrawal`)
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_WITHDRAWAL_NOTIFICATIONS,
            payload: data.data.data,
          });
        } else {
          dispatch(fetchError('Something went wrong, Please try again!'));
        }
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getFinanceData = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    await jwtAxios
      .get('adminFinance')
      .then((data: any) => {
        dispatch(fetchSuccess());
        dispatch({type: SET_FINANCE_LIST, payload: data.data.data});
        console.log('data =>', data);
      })
      .catch((error: any) => {
        dispatch(fetchError(error.message));
      });
  };
};
